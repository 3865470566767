import React from 'react';
import {getRoutes} from '../routes';
import {graphql, useStaticQuery} from 'gatsby';
import css from './footer.module.scss';
import useBreakpoint from "../../hooks/useBreakpoint";
import FacebookIcon from "../../../images/externalApps/fb_icon_w.svg";
import LinkedInIcon from "../../../images/externalApps/in_icon_w.svg";
import InstagramIcon from "../../../images/externalApps/insta_icon_w.svg";
import YouTubeIcon from "../../../images/externalApps/yt_icon_w.svg";
import TwitterIcon from "../../../images/externalApps/X_logo.svg";
import PinterestIcon from "../../../images/externalApps/pinterest_icon_w.svg";
import UE from "../../../images/footer/EU_footer.svg";
import UEMobile from "../../../images/footer/EU_footer_m.svg";
import DeeplaiButton from "../DeeplaiButton/DeeplaiButton";
import SubpageLink from "../InternalLinks/SubpageLink";
import {getImagesFluid} from "../../componentsUtils";
import BuyAppButtons, {buyAppButtonsVersions} from "../BuyAppButtons/BuyAppButtons";
import NvidiaLogo from "../../../images/footer/nvidia_inception.png";
import AwsLogo from "../../../images/footer/aws_logo_poweredby.png";

const Footer = props => {
    const queryResult = useStaticQuery(
        graphql`
      query {
        fluid: allFile(filter: {sourceInstanceName: {eq: "images_footer"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 424, quality: 70) {
                ...GatsbyImageSharpFluid,
                originalName
              }
            }
          }
        }
        site {
          siteMetadata {
            socialLinks {
              facebook
              twitter
              instagram
              linkedin
              youtube
              pinterest
            }
          }
        }
      }
    `
    );

    const {site} = queryResult;

    const imagesFluid = getImagesFluid(queryResult);

    const breakpoint = useBreakpoint();

    const socialLinks = site.siteMetadata.socialLinks;

    // TODO
    // renderHeaderSubpageLink
    // renderHeaderSectionLink

    const renderSubpageLink = (subpage, text, className = '') => {
        return (
            <SubpageLink subpage={subpage}>
                <div className={[css.navigationLink, className].join(' ')}>
                    {text}
                </div>
            </SubpageLink>
        )
    };

    const renderTechnologyLink = (img, imgWidth = 'auto', url) => {
        return (
            <div className={css.technologyLogoLink}>
                <a href={url} target={'_blank'}>
                    <div style={{width: imgWidth}}>
                        {img}
                    </div>
                </a>
            </div>
        );
    };

    const routes = getRoutes();
    return (
        <div className={css.container} data-viewport={breakpoint.viewport}>

            <div className={css.wrapper}>
                <div className={css.line}/>
                <div className={css.wrapperInner}>
                    <div className={css.navigationWrapper}>
                        <div className={css.navigationWrapperInner}>
                            <div className={[css.column, css.firstColumn].join(' ')}>
                                <div className={css.logoAndCopyright}>
                                    <div className={css.logoWrapper}>
                                        <DeeplaiButton height={'35px'} version={'white'}/>
                                    </div>
                                    <div className={css.text}>
                                        &copy; Deeplai {new Date().getFullYear()}. <div
                                        className={css.firstColumnBreak}/>All rights reserved.
                                    </div>
                                </div>

                                <div className={css.socialMediaWrapper}>
                                    <div className={css.socialMedia}>
                                        <a href={socialLinks.facebook} title="Facebook" target="_blank"
                                           className={css.link}>
                                            <img src={FacebookIcon} alt="" className={css.FacebookIcon}/>
                                        </a>
                                        <a href={socialLinks.twitter} title="Twitter" target="_blank"
                                           className={css.link}>
                                            <img src={TwitterIcon} alt="" className={css.TwitterIcon}/>
                                        </a>
                                        <a href={socialLinks.linkedin} title="LinkedIn" target="_blank"
                                           className={css.link}>
                                            <img src={LinkedInIcon} alt="" className={css.LinkedInIcon}/>
                                        </a>
                                        <div className={css.firstColumnBreak}/>
                                        <a href={socialLinks.instagram} title="Instagram" target="_blank"
                                           className={css.link}>
                                            <img src={InstagramIcon} alt="" className={css.InstagramIcon}/>
                                        </a>
                                        <a href={socialLinks.youtube} title="YouTube" target="_blank"
                                           className={css.link}>
                                            <img src={YouTubeIcon} alt="" className={css.YouTubeIcon}/>
                                        </a>
                                        <a href={socialLinks.pinterest} title="Pinterest" target="_blank"
                                           className={css.link}>
                                            <img src={PinterestIcon} alt="" className={css.PinterestIcon}/>
                                        </a>
                                    </div>
                                </div>
                                <BuyAppButtons version={buyAppButtonsVersions.footer}/>
                                <div className={css.sectionImageWrapper}>
                                    <div className={css.links}>
                                        <a href={"https://www.nvidia.com/en-au/deep-learning-ai/startups/"}
                                           target="_blank"><img src={NvidiaLogo} alt={'nvidia logo'}/></a>
                                        <a href={"https://aws.amazon.com/"} target="_blank"><img src={AwsLogo}
                                                                                                 alt={'aws logo'}/></a>
                                    </div>
                                </div>
                            </div>

                            <div className={css.column}>
                                {renderSubpageLink([routes.home], 'Home', css.subpageBoldLink)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.Traceability], routes.Traceability.pageTitle, css.subpageBoldLink)}
                                {renderSubpageLink([routes.TraceabilityForest], routes.TraceabilityForest.pageTitle)}
                                {renderSubpageLink([routes.TraceabilityForestStreet], routes.TraceabilityForestStreet.pageTitle)}
                                {renderSubpageLink([routes.TraceabilityLogistics], routes.TraceabilityLogistics.pageTitle)}
                                {renderSubpageLink([routes.TraceabilitySawmillTrader], routes.TraceabilitySawmillTrader.pageTitle)}
                                {renderSubpageLink([routes.TraceabilityAtTheSawmill], routes.TraceabilityAtTheSawmill.pageTitle)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.Technology], 'Technology', css.subpageBoldLink)}
                                {renderSubpageLink([routes.TechnologyMobileApps], routes.TechnologyMobileApps.pageTitle)}
                                {renderSubpageLink([routes.TechnologyDMS], routes.TechnologyDMS.pageTitle)}
                                {renderSubpageLink([routes.TechnologyDMASAAutomated], routes.TechnologyDMASAAutomated.pageTitle)}
                                {renderSubpageLink([routes.TechnologyDMH], routes.TechnologyDMH.pageTitle)}
                                {renderSubpageLink([routes.TechnologyDMASAutomated], routes.TechnologyDMASAutomated.pageTitle)}
                                {renderSubpageLink([routes.TechnologyEDG], routes.TechnologyEDG.pageTitle)}
                                {renderSubpageLink([routes.TechnologyODR], routes.TechnologyODR.pageTitle)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.Platform], 'Platform', css.subpageBoldLink)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.Offering], 'Offering', css.subpageBoldLink)}
                                {renderSubpageLink([routes.OfferingDcodeApplyAndRead], routes.OfferingDcodeApplyAndRead.pageTitle)}
                                {renderSubpageLink([routes.OfferingTimberIDAuthenticationPlatform], routes.OfferingTimberIDAuthenticationPlatform.pageTitle)}
                                {renderSubpageLink([routes.OfferingTimberIDEUDRPlatform], routes.OfferingTimberIDEUDRPlatform.pageTitle)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.About], 'Company', css.subpageBoldLink)}
                                {renderSubpageLink([routes.About], routes.About.pageTitle)}
                                {renderSubpageLink([routes.news], routes.news.pageTitle)}
                                {renderSubpageLink([routes.Partnership], routes.Partnership.pageTitle)}
                                {renderSubpageLink([routes.Careers], routes.Careers.pageTitle)}
                                {renderSubpageLink([routes.LegalDocuments], routes.LegalDocuments.pageTitle)}
                                {renderSubpageLink([routes.PrivacyPolicy], routes.PrivacyPolicy.pageTitle)}
                                {renderSubpageLink([routes.EuropeanFunds], routes.EuropeanFunds.pageTitle)}
                                {renderSubpageLink([routes.Contact], routes.Contact.pageTitle)}
                            </div>
                            <div className={css.column}>
                                {renderSubpageLink([routes.Eudr], 'EUDR', css.subpageBoldLink)}
                            </div>
                            {/*<span className={css.LinkWrapper}>
                            </span>*/}
                        </div>


                    </div>
                </div>
                <div className={css.line}/>
                <div className={css.ueRow}>
                    <img className={css.fullImg} src={UE} alt=""/>
                    <img className={css.phoneImg} src={UEMobile} alt=""/>
                </div>
                <div className={css.line}/>
                <div className={css.trademarkRow}>
                    <div className={css.trademarkRowInner}>
                        All trademarks, service marks, trade names, product names, logos and trade dress appearing on
                        our website are the property
                        of their respective owners. "AWS", "Amazon Web Services", "Powered by AWS" logo are trademarks
                        of Amazon.com, Inc. or its affiliates in the United States and/or other countries. NVIDIA, the
                        NVIDIA logo, and all other NVIDIA product names mentioned
                        in this website are trademarks and/or registered trademarks of NVIDIA Corporation in the U.S.
                        and other countries.
                        Apple, the Apple logo, MacBook Air, MacBook Pro, and iMac are trademarks of Apple Inc.,
                        registered in the U.S. and other countries. Mac App Store is a service mark of Apple Inc.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

